import './online-booking-page.css';
import { Button, Skeleton } from 'antd';
import { SalesCallCalendar } from '../../appointment-booking/sales-call-calendar';
import { useEffect, useState } from 'react';
import { OpportunityComplete, CaseInfo, CaseStatus, Slot, SalesChannel } from '../../../dto/model';
import { strings } from '../../../lang';
import { CustomerLostInfo, CustomerLostModal } from '../modal/customer-lost-modal';
import CustomerInfoSection from './online-booking-page-cust-info';
import { NotReachedModal } from '../modal/not-reached-modal';
import { ReachedButNotBookedBody, ReachedButNotBookedInfo } from '../modal/reached-not-booked';
import CaseCommentSection from './online-booking-page-case-comments';
import WrongPhoneNumberModal from '../modal/wrong-phone-number-modal';

type OnlineBookingPageProps = {
  caseInfo: CaseInfo | null;
  setCaseInfo: (caseInfo: CaseInfo) => void;
  cancelledSlot: Slot | null;
  inputCaseComments: string;
  setInputCaseComments: (inputComments: string) => void;
  onCaseClosed: (caseComplete: OpportunityComplete, bookedSlot?: Slot) => Promise<void>;
  inboundCenter?: boolean | undefined;
  onInboundCallback?: (bookedSlot: Slot | undefined) => void;
};

export const OnlineBookingPage = ({ caseInfo, setCaseInfo, cancelledSlot, inputCaseComments, setInputCaseComments, onCaseClosed, inboundCenter, onInboundCallback }: OnlineBookingPageProps) => {
  const [showWrongPhoneNumberModal, setShowWrongPhoneNumberModal] = useState<boolean>(false);
  const [loadCalendar, setLoadCalendar] = useState<boolean>(false);
  const [showCustomerLost, setShowCustomerLost] = useState<boolean>(false);
  const [showNotReachedModal, setShowNotReachedModal] = useState<boolean>(false);
  const [showReachedButNotBookedBody, setShowReachedButNotBookedBody] = useState<boolean>(false);

  const onBooking = async (isSuccess: boolean, bookedSlot?: Slot, error?: string) => {
    if (isSuccess) {
      await onCaseClosed(
        {
          id: caseInfo!.opportunityId,
          globalCustomerId: caseInfo!.globalCustomerId,
          caseId: caseInfo!.caseId,
          customerLostReasons: null,
          customerLostDescription: null,
          opportunityComments: inputCaseComments,
          doNotCallBefore: null,
          caseStatus: CaseStatus.Booked,
        },
        bookedSlot
      );
    }
    //If the case is already closed and a user tries to book, close to case on our end but do not send a booked slot.
    //By not sending the booked slot, up the chain it will trigger an error message to the user.
    else if (!isSuccess && error?.includes('case is already closed')) {
      await onCaseClosed({
        id: caseInfo!.opportunityId,
        globalCustomerId: caseInfo!.globalCustomerId,
        caseId: caseInfo!.caseId,
        customerLostReasons: null,
        customerLostDescription: null,
        opportunityComments: inputCaseComments,
        doNotCallBefore: null,
        caseStatus: CaseStatus.Booked,
      });
    }
  };

  useEffect(() => {
    // This useEffect can be used to clean up the state of previous cases.
    setLoadCalendar(false);
    setInputCaseComments('');
  }, [caseInfo?.caseId]);

  if (!caseInfo) {
    return <Skeleton></Skeleton>;
  }

  return (
    <div>
      <CustomerInfoSection
        inboundCenter={inboundCenter}
        caseInfo={caseInfo}
        setCaseInfo={setCaseInfo}
        cancelledSlot={cancelledSlot}
        showWrongPhoneNumberModal={setShowWrongPhoneNumberModal} />
      <CaseCommentSection
        caseComments={caseInfo.caseComments.filter((item) => !item.isCustomerLostReason).sort((b, a) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime())}
        inputCaseComment={inputCaseComments}
        onInputCaseCommentChange={setInputCaseComments}
        shouldShowPreviousComments={caseInfo?.salesChannel === SalesChannel.Revision ? true : false}
      />

      <section className="bp-section">
        <div className="bp-calendar__buttons">
          {!loadCalendar && (
            <Button type="primary" onClick={() => setLoadCalendar(true)}>
              {strings.loadCalendar}
            </Button>
          )}
          <Button
            onClick={() => {
              setShowReachedButNotBookedBody(true);
            }}
          >
            {strings.modalTitleReachedNotBooked}
          </Button>
          <Button
            disabled={inboundCenter}
            onClick={() => {
              setShowNotReachedModal(true);
            }}
          >
            {strings.modalTitleNotReached}
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              setShowCustomerLost(true);
            }}
          >
            {caseInfo?.salesChannel === SalesChannel.Revision ? strings.looseCustomerButton : strings.modalTitleCustomerLost}
          </Button>
        </div>
      </section>

      {loadCalendar && (
        <section className="bp-section bp-calendar">
          <SalesCallCalendar opportunityId={caseInfo?.opportunityId!} displayHeader={false} onBooking={onBooking} skipConfirmationAfterBooking={true} />
        </section>
      )}

      <WrongPhoneNumberModal
        open={showWrongPhoneNumberModal}
        onOk={async (message: string) => {
          await onCaseClosed({
            id: caseInfo.opportunityId,
            globalCustomerId: caseInfo.globalCustomerId,
            caseId: caseInfo!.caseId,
            customerLostReasons: null,
            customerLostDescription: null,
            opportunityComments: message,
            doNotCallBefore: null,
            caseStatus: CaseStatus.WrongNumber,
          });
          setShowWrongPhoneNumberModal(false);
        }}
        showModal={setShowWrongPhoneNumberModal}
      />

      <CustomerLostModal
        salesChannel={caseInfo.salesChannel!}
        inboundCenter={inboundCenter}
        open={showCustomerLost}
        onOk={async (customerLostInfo: CustomerLostInfo) => {
          await onCaseClosed({
            id: caseInfo.opportunityId,
            globalCustomerId: caseInfo.globalCustomerId,
            caseId: caseInfo!.caseId,
            customerLostReasons: customerLostInfo.reasons,
            customerLostDescription: customerLostInfo.description,
            opportunityComments: inputCaseComments,
            doNotCallBefore: null,
            caseStatus: CaseStatus.CustomerLost,
            shareContactData: customerLostInfo.shareContactData,
          });
          setShowCustomerLost(false);
          if (inboundCenter) {
            onInboundCallback?.(undefined);
          }
        }}
        onCancel={() => {
          setShowCustomerLost(false);
        }}
      />

      <NotReachedModal
        open={showNotReachedModal}
        onCancel={() => setShowNotReachedModal(false)}
        onOk={async () => {
          await onCaseClosed({
            id: caseInfo.opportunityId,
            globalCustomerId: caseInfo.globalCustomerId,
            caseId: caseInfo.caseId,
            customerLostReasons: null,
            customerLostDescription: null,
            opportunityComments: inputCaseComments,
            doNotCallBefore: null,
            caseStatus: CaseStatus.NotReached,
          });
          setShowNotReachedModal(false);
        }}
      />

      <ReachedButNotBookedBody
        open={showReachedButNotBookedBody}
        onCancel={() => setShowReachedButNotBookedBody(false)}
        inboundCenter={inboundCenter}
        onOk={async (reachedButNotBookedInfo: ReachedButNotBookedInfo) => {
          await onCaseClosed({
            id: caseInfo.opportunityId,
            globalCustomerId: caseInfo.globalCustomerId,
            caseId: caseInfo.caseId,
            customerLostReasons: null,
            customerLostDescription: null,
            opportunityComments: inputCaseComments,
            doNotCallBefore: reachedButNotBookedInfo.dateToCall.toDate(),
            caseStatus: CaseStatus.ReachedButNotBooked,
          });
          setShowReachedButNotBookedBody(false);
          if (inboundCenter) {
            onInboundCallback?.(undefined);
          }
        }}
      />
    </div>
  );
};
