import React, { useState } from 'react';
import { Modal, Input, Button, Row, Col, message, notification } from 'antd';
import { CaseInfo, OpportunityEditContactDto as EditContactDto } from '../../../dto/model';
import { set } from 'date-fns';
import { salesAppointmentsService } from '../../../services/sales-appointments-service';
import { bookingPageService } from '../../../services/booking-page-service';
import { GetAccessToken } from '../../../utils/auth-utils';
import { scopes } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { useSalesChannelState } from '../../../store/header-state';

type CustomerInfoEditModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  caseInfo: CaseInfo;
  setCaseInfo: (caseInfo: CaseInfo) => void;
}

const CustomerInfoEditModal = ({ isOpen, setIsOpen, caseInfo, setCaseInfo }: CustomerInfoEditModalProps) => {
  const salesForceRootUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;
  const { instance, inProgress } = useMsal();
  const [editCaseInfo, setEditCaseInfo] = useState<CaseInfo>(caseInfo);
  const { salesChannel } = useSalesChannelState();
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = async () => {
    setIsLoading(true);
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const editContactDto: EditContactDto = {
      firstName: editCaseInfo.firstName,
      lastName: editCaseInfo.lastName,
      salutation: editCaseInfo.salutation,
      email: editCaseInfo.email,
      phone: editCaseInfo.phone,
      opportunityId: caseInfo.opportunityId,
      globalCustomerId: caseInfo.globalCustomerId,
      contactId: caseInfo.contactId,
    };

    const updateResult = await bookingPageService.updateContact(editContactDto, salesChannel, token.accessToken);
    setIsLoading(false);
    if (!updateResult.isSuccess) {
      notification.error({
        message: `Failed to update contact. Errors: ${updateResult.errors}`,
      });
      return;
    }
    notification.info({
      message: 'Contact updated successfully'
    });
    setCaseInfo(editCaseInfo);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const openSalesforce = () => {
    window.open(`${salesForceRootUrl}/lightning/r/Contact/${caseInfo.contactId}/view`);
  }
  return (
    <>
      <Modal
        title="Edit Contact Data"
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        keyboard={false}
        okText="Save"
        cancelText="Cancel"
        width={800}
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={4}>
            <label style={{ fontWeight: 'bold' }}>Salutation</label>
            <Input
              value={editCaseInfo.salutation}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, salutation: e.target.value })}
              placeholder="First Name"
            />
          </Col>
          <Col span={10}>
            <label style={{ fontWeight: 'bold' }}>First Name</label>
            <Input
              value={editCaseInfo.firstName}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, firstName: e.target.value })}
              placeholder="First Name"
            />
          </Col>
          <Col span={10}>
            <label style={{ fontWeight: 'bold' }}>Last Name</label>
            <Input
              value={editCaseInfo.lastName}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, lastName: e.target.value })}
              placeholder="Last Name"
            />
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={12}>
            <label style={{ fontWeight: 'bold' }}>Phone Number</label>
            <Input
              value={editCaseInfo.phone}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, phone: e.target.value })}
              placeholder="Phone"
            />
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={12}>
            <label style={{ fontWeight: 'bold' }}>Email</label>
            <Input
              value={editCaseInfo.email}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, email: e.target.value })}
              placeholder="Email"
            />
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            marginBottom: 16,
          }}
        >
          <Col span={8}>
            <label style={{ fontWeight: 'bold' }}>Street</label>
            <Input
              disabled
              value={editCaseInfo.street}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, street: e.target.value })}
              placeholder="Street"
            />
          </Col>
          <Col span={8}>
            <label style={{ fontWeight: 'bold' }}>Zipcode</label>
            <Input
              disabled
              value={editCaseInfo.zipcode}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, zipcode: e.target.value })}
              placeholder="Zipcode"
            />
          </Col>
          <Col span={8}>
            <label style={{ fontWeight: 'bold' }}>City</label>
            <Input
              disabled
              value={editCaseInfo.city}
              onChange={(e) => setEditCaseInfo({ ...editCaseInfo, city: e.target.value })}
              placeholder="City"
            />
          </Col>
        </Row>
        <Button onClick={openSalesforce}>Edit In Salesforce</Button>
      </Modal >
    </>
  );
};

export default CustomerInfoEditModal;